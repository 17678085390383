<template>
    <div>
        <st-data-table
            headClass="st-data-table-head"
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @download="onDownload"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { DocumentModel } from '@/modules/control/models/document-model';
    const { fields } = DocumentModel;

    export default {
        name: 'ControlDocumentsListTable',
        data() {
            return {
                presenter: DocumentModel.presenter,
                files: [],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'control/form/filteredDocs',
                currentUser: 'auth/currentUser',
                fileToken: 'control/form/fileToken',
                record: 'control/form/record',
            }),
            fields() {
                return [
                    fields.created_by,
                    fields.filename,
                    fields.created_date,
                    fields.status.extendField({ labelType: true }),
                ]
            },
            actions(){
                return [{
                    name: 'download',
                    icon: 'download',
                    tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                    customIcon: false,
                    type: 'primary'
                }];
            },
        },
        methods: {
            ...mapActions({
                downloadDocument: 'control/form/downloadDocument',
            }),
            onDownload(doc){
                window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileToken}`, '_blank');
            },
        },
    };
</script>
