
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { ControlService } from '@/modules/control/control-service';

export class ControlAssignedUserField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            ControlService.getControlsUsersList,
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    value: record.assigned_staff_user_id,
                    text: record.assigned_staff_user_name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
