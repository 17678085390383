import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import ComputedField from '@/shared/fields/computed-field';
import { ControlTypeField } from '@/modules/control/fields/control-type-field';
import { ControlAssignedUserField } from '../fields/control-assigned-user-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';

const label = (name) => i18n.t(`CONTROL.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`CONTROL.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`CONTROL.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const STATUSES = [
    {
        value: 'registered',
        name: 'registered',
        text: enumLabel('status', 'registered'),
        type: 'primary',
        sortkey: 1
    },
    {
        value: 'resolved',
        name: 'resolved',
        text: enumLabel('status', 'resolved'),
        type: 'success',
        sortkey: 2
    },
    {
        value: 'closed',
        name: 'closed',
        text: enumLabel('status', 'closed'),
        type: 'danger',
        sortkey: 3
    },
    {
        value: 'error',
        name: 'error',
        text: enumLabel('status', 'error'),
        type: 'warning',
        sortkey: 4
    },
];

const fields = {
    id: new IdField('id', 'Id'),
    identification_number: new StringField('identification_number', label('identification_number'), {},
        {
            placeholder: placeholder('identification_number'),
            primary: true,
        }),
    control_type_name: new StringField('control_type_name', label('control_type_name_column'),{},
        {
            placeholder: placeholder('control_type_name'),
            primary: false,
        }),
    control_type_id: ControlTypeField.relationToOne(
        'control_type_id',
        label('control_type_name'),
        { autocompleteRequired: true },
        { placeholder: placeholder('control_type_name')},
        [],
        { asyncData: true, hasPagination: true }
    ),
    status: new EnumField(
        'status',
        label('status'),
        STATUSES,
        {},
        { placeholder: placeholder('status')}
    ),
    created_date: new DateField('created_date', label('created_date')),
    control_date: new DateField('control_date', label('control_date')),
    assigned_staff_user_name: new ComputedField('assigned_staff_user_name', label('assigned_to'),
        ({assigned_staff_user_name}) => {
            if (!assigned_staff_user_name) return i18n.t('CONTROL.LIST.NO_ASSIGNEE');
            return assigned_staff_user_name;
        },
        {},
        {
            placeholder: placeholder('assigned_to'),
            primary: false,
        }
    ),
    assigned_staff_user_id: ControlAssignedUserField.relationToOne('created_by', label('created_by'), {}, {
        placeholder: placeholder('created_by'),
    }, [], { asyncData: true }),
    staff_name: new ComputedField('created_by', label('created_by'),
        (record) => {
            if (!record.form?.staff?.first_name)  return '';
            return `${record.form?.staff?.first_name} ${record.form?.staff?.last_name} `;
        }
    ),
    locality: new StringField('locality', label('locality'),{} ,
    {
        placeholder: placeholder('locality'),
    }),
    allocated_document_series: new StringField('allocated_document_series', label('allocated_document_series'), {},
    {
        placeholder: placeholder('allocated_document_series'),
    }),
    // Filters
    territorial_administrative_unit_id: TerritorialAdministrativeUnitField.relationToOne(
        'form.realty.territorial_administrative_unit_id',
        label('territorial_administrative_unit'),
        { autocompleteRequired: true },
        {
            placeholder: placeholder('territorial_administrative_unit') ,
            type: 'autocomplete',
            primary: false,
        },
    ),
    comments: new StringField('comments', label('comments'), { required: true },
    {
        placeholder: placeholder('comments'),
    }),
};

export class ControlModel extends GenericModel {
    static get fields() {
        return fields;
    }
    static get statuses() {
        return STATUSES;
    }
}
