<template>
    <st-page layout="tab" :showHeader="false">
        <control-activities
            :items="activities"
            :record="record"
        />
    </st-page>
</template>

<script>
import ControlActivities from '@/modules/control/components/activities/ControlActivities';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ApplicationActivityTab',
    components: {
        ControlActivities,
    },
    props: {
        controlId: String,
    },
    data() {
        return {
            polling: null,
        }
    },
    computed: {
        ...mapGetters({
            activities: 'control/activity/activities',
            record: 'control/form/record'
        }),
    },
    methods: {
        ...mapActions({
            getActivities: 'control/activity/getActivities'
        }),
        updateActivities(controlId) {
            this.getActivities(controlId);
        }
    },
    async mounted() {
        if (this.record && this.record.id && !this.polling) {
            this.polling = setInterval(async () => {
                await this.getActivities(this.record.id);
            }, 5000);
        }
    },
    async created() {
        if (this.record && this.record.id) {
            await this.getActivities(this.record.id);
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
        this.polling = null;
    }
}
</script>
