<template>
    <st-page :showHeader="false" class="mt-6">
        <control-documents-list-filter>
            <template #toolbar>
                <control-documents-list-toolbar />
            </template>

            <control-documents-list-table />
        </control-documents-list-filter>
    </st-page>
</template>
<script>
import ControlDocumentsListFilter from './ControlDocumentsListFilter';
import ControlDocumentsListTable from './ControlDocumentsListTable';
import ControlDocumentsListToolbar from './ControlDocumentsListToolbar';

export default {
    name: 'ControlDocumentsList',
    components: {
        ControlDocumentsListFilter,
        ControlDocumentsListTable,
        ControlDocumentsListToolbar,
    },
}
</script>