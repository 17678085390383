<template>
    <div>
        <st-section :header="$t('CONTROL.PREPARATION.HEADER')">
            <template #body>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group ">
                            <label class="font-size-h6">{{$t('CONTROL.FIELDS.COMPLAIN_NUMBER')}}</label>
                            <div class="d-flex justify-content-start mt-2">
                                <st-search
                                    v-model="selectedComplain"
                                    :options="options"
                                    ref="selectedComplain"
                                    name="selectedComplain"
                                    class="flex-grow-1"
                                    :searchFnc="onInputChange"
                                    :searchText="$t('CONTROL.PREPARATION.SEARCH_FOR')"
                                    @selected="processSelected"
                                    :disabled="viewMode"
                                    ></st-search>
                                <st-button
                                    variant="outline-primary"
                                    :callback="addComplain"
                                    class="ml-3"
                                    :disabled="viewMode"
                                >
                                    <i class="fa fa-plus"></i>
                                    {{$t('CONTROL.BUTTON.ADD_COMPLAIN')}}
                                </st-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label class="font-size-h6">{{$t('CONTROL.PREPARATION.COMPLAIN_LIST_TAGS')}}</label>
                            <div class="d-flex justify-content-start mt-2">
                                <b-form-tags v-model="complainTags" no-outer-focus>
                                    <template v-slot="{ tags, tagVariant, removeTag }">
                                        <div class="d-inline-block">
                                            <b-form-tag
                                                v-for="tag in tags"
                                                @remove="removeTag(tag)"
                                                :key="tag"
                                                :title="tag"
                                                :variant="tagVariant"
                                                class="mr-1 custom-b-form-tag"
                                                :disabled="viewMode"
                                                >{{ tag }}</b-form-tag>
                                        </div>
                                    </template>
                                </b-form-tags>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </st-section>

        <div class="st-control-preparation">
            <div class="mt-6">
                <div  v-for="(item, id) in locationFormList" :key="item.form" :id="`st_location_section_${id}`">
                    <LocationForm
                        :id="id"
                        :item="item"
                        ref="locationForm"
                        @removeLocation="removeLocation"
                        :viewMode="viewMode"
                        />
                </div>
            </div>

            <st-section
                :header="$t('CONTROL.PREPARATION.PREVIEW_MAP')">
                <template #body>
                    <location-map
                        v-if="!isModalOpen"
                        :viewMode="true"
                        :address="address"
                        :location="location"
                        :locations="locationFormList"
                    />
                </template>
            </st-section>

            <div class="d-flex mt-6 justify-content-end">
                <st-button
                    variant="primary"
                    :callback="nextStep"
                >
                    {{$t('CONTROL.BUTTON.NEXT_STEP')}}
                </st-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import /* webpackChunkName: "locationmap" */ LocationMap from "@/modules/complain/components/LocationMap.vue";
import LocationForm from "@/modules/control/components/LocationForm.vue";

import { BFormTags, BFormTag } from 'bootstrap-vue';

export default {
    name: 'ControlPreparation',
    components: {
        BFormTags,
        BFormTag,
        LocationMap,
        LocationForm
    },
    data: () => ({
        location: null,
        address: '',
        selectedComplain: '',
        locationFormList: [],
        isModalOpen: false,
        complains: [],
        complainTags: [],
        index: 0,
        isComplainRemovedFromLocation: false,
        searchValue: "",
        minValueLength: 2,
        currentComplain: null,
    }),
    computed: {
         ...mapGetters({
            record: 'control/form/record',
            selectedComplains: 'control/form/currentComplains',
        }),
        viewMode() {
            return this.record && !this.record.can_edit_control;
        },
        options() {
            return this.complains;
        },
        canEdit() {
            return this.record?.status === 'registered';
        }
    },
    watch: {
        complainTags(newVal, oldVal) {;
            const removed = oldVal.find(v => !newVal.includes(v));
            if(removed && removed.length > 0) {
                // remove location from list
                const complainLocationIndex = this.locationFormList.findIndex(location => location?.identification_number === removed);

                // Remove from location list only if is removed from tags
                if (!this.isComplainRemovedFromLocation) {
                    this.locationFormList.splice(complainLocationIndex, 1);
                }
            }
        },
    },
    methods: {
        ...mapActions({
            doFindComplains: "control/form/findComplain",
            doSelectComplains: "control/form/selectComplains",
        }),
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
        addComplain() {
            const tagExists = this.complainTags.find((el) => el === this.currentComplain.text);

            if (!tagExists) {
                this.complainTags.push(this.currentComplain.text);
                const complain = this.currentComplain.complain;

                this.locationFormList.push({
                    form: this.index,
                    address: complain.address,
                    location: complain.location,
                    uat: {
                        locality_name: complain.uat.locality_name
                    },
                    identification_number: complain.identification_number,
                    id: complain.id
                });

                this.index++;
            }

            this.selectedComplain = '';

        },
        removeLocation(data) {
            this.locationFormList.splice(data.id, 1);
            // Remove from tags  if location is based on complain
            if (data.location) {
                this.isComplainRemovedFromLocation = true;
                this.complainTags = this.complainTags.filter(complain => complain !== data.location.identification_number);
            }
        },
        onChangeComplain() {
            this.isComplainRemovedFromLocation = false;
        },
        nextStep() {
            const complainData = this.locationFormList.map((el) => ({
                id:  el.id,
                identification_number: el.identification_number,
                address: el.address,
                location: el.location,
                uat: {
                    locality_name: el.uat.locality_name
                }
            }));

            this.doSelectComplains(complainData);

            this.$emit('nextStep', complainData);
        },
        async onInputChange(value) {
            if (value.length > this.minValueLength){
                const complains = await this.doFindComplains({
                    params: {
                        limit: 25,
                        query: `identification_number contains \'${value}\'`
                    }
                });

                return complains.map((row) => ({
                    value: row.id,
                    text: row.identification_number,
                    complain: row,
                }));
            }
        },
        processSelected(data) {
            this.complains = data.options;
            this.currentComplain = this.complains.find( complain => complain.value === this.selectedComplain);
        },
    },
    created() {
        if (this.selectedComplains.length) {
            this.locationFormList = this.selectedComplains;
            this.complainTags = this.selectedComplains.map((el) => el.identification_number);
        } else if (this.record?.related_complains) {
            this.locationFormList = this.record.related_complains;
            this.complainTags = this.record.related_complains.map((el) => el.identification_number);
        }
    },
}
</script>
