<template>
    <div>
        <Loader v-if="loadingGeneration || loadingPreview"/>
        <st-page
            :title="title"
            layout="tab"
            :backLink="backLink"
        >
            <template #toolbar>
                <st-button
                    v-if="canCloseControl"
                    :callback="closeControl"
                    class="st-btn-text-danger"
                    variant="light"
                >
                    <i class="fas fa-archive"></i>
                    {{ $t('CONTROL.BUTTON.CLOSE_CONTROL') }}
                </st-button>
                <st-button
                    v-if="canPreviewDocument"
                    variant="light"
                    :spinner="loadingPreview"
                    :disabled="loadingPreview"
                    :callback="previewDocument"
                >
                    <i class="fas fa-file"></i>
                    {{ $t('CONTROL.BUTTON.PREVIEW') }}
                </st-button>
                <st-button
                    v-if="canGenerateDocuments"
                    variant="secondary"
                    :spinner="loadingGeneration"
                    :disabled="loadingGeneration"
                    :callback="createControlDocuments"
                >
                    <i class="fas fa-shield-alt"></i>
                    {{ $t('CONTROL.BUTTON.GENERATE_PVCC') }}
                </st-button>
            </template>
            <b-tabs class="st-tabs" justified lazy v-model="activeTab">
                <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled">
                    <template #title>
                        <div class="d-flex justify-content-center w-100">
                            <i :class="['fas', tab.icon]"></i>
                            <span class="text-uppercase ml-4">{{ tab.titleKey }}</span>
                        </div>
                    </template>
                    <component
                        v-if="!isLoading"
                        :is="tab.componentType"
                        @nextStep="nextStep"
                    >
                    </component>
                </b-tab>
            </b-tabs>
        </st-page>
    </div>
</template>

<script>
import ControlPreparation from "../components/ControlPreparation";
import ControlDataCollection from "../components/ControlDataCollection";
import ControlDocumentsList from "../components/documents/ControlDocumentsList";
import ControlActivityTab from "../components/activities/ControlActivityTab";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ControlForm',
    components: {
        ControlPreparation,
        ControlDataCollection,
        ControlDocumentsList,
        ControlActivityTab,
    },
    props: {
        id: String,
    },
    data() {
        return {
            activeTab: 0,
            loadingGeneration: false,
            documentStatus: '',
            polling: null,
            disableTab: [
                false,
                true,
                true,
                true
            ],
            loadingPreview: false,
            previewPolling: '',
        };
    },
    computed: {
        ...mapGetters({
            record: 'control/form/record',
            canGenerateDocuments: 'control/form/canGenerateDocuments',
            canCloseControl: 'control/form/canCloseControl',
            loading: 'shared/loading',
            canPreviewDocument: 'control/form/canPreviewDocument',
        }),
        backLink() {
            return  { name: 'controls' } ;
        },
        title() {
            if (!this.id) return this.$t('CONTROL.FORM.NEW_CONTROL');
            
            return this.$t('CONTROL.FORM.EDIT_TITLE', [this.record?.identification_number]);
        },
        tabs() {
            return [
                {
                    index: 0,
                    name: 'control-preparation',
                    titleKey: this.$t('CONTROL.TABS.CONTROL_PREPARATION'),
                    icon: 'fa-edit',
                    componentType: 'ControlPreparation',
                    disabled: this.disableTab[0],
                },
                {
                    index: 1,
                    name: 'data-collection',
                    icon: 'fa-edit',
                    titleKey: this.$t('CONTROL.TABS.DATA_COLLECTION'),
                    componentType: 'ControlDataCollection',
                    disabled: this.disableTab[1],
                },
                {
                    index: 2,
                    name: 'pvcc',
                    icon: 'fa-file',
                    titleKey: this.$t('CONTROL.TABS.DOCUMENTS'),
                    componentType: 'ControlDocumentsList',
                    disabled: this.disableTab[2],
                },
                {
                    index: 3,
                    name: 'activity',
                    icon: 'fa-list',
                    titleKey: this.$t('CONTROL.TABS.ACTIVITIES'),
                    componentType: 'ControlActivityTab',
                    disabled: this.disableTab[3],
                },
            ];
        },
        isLoading() {
            return this.loading['control/find'];
        },
    },
    methods: {
        ...mapActions({
            doFindRecord: 'control/form/find',
            initDefaultState: 'control/form/initFormState',
            doGenerateDocuments: 'control/form/generateDocuments',
            doCheckGenerationStatus: 'control/form/checkGenerationStatus',
            doCloseControl: 'control/form/closeControl',
            doPreviewDocument: 'control/form/previewDocument',
            doCheckPreviewStatus: 'control/form/checkPreviewStatus',
        }),
        nextStep(data) {
            if (data?.redirect) {
                this.$router.push({
                    name: 'controlEdit',
                    params: {
                        id: data.id
                    }
                });

                this.disableTab = this.disableTab.map(() => false);
            } else {
                this.$set(this.disableTab, this.activeTab + 1, false);
                
                this.$nextTick(() => {
                    this.activeTab++;
                })
            }
        },
        async createControlDocuments() {
            const confirmCallback = async () => {
                try {
                    this.loadingGeneration = true;
                    const response = await this.doGenerateDocuments(this.id);
                    switch(response) {
                        case 'error':
                            this.documentStatus = response;
                            this.loadingGeneration = false;
                            break;
                        case 'initiated':
                            this.polling = setInterval(() => {
                                this.checkDocumentStatus(this.id);
                            }, 1000);
                            break;
                    }
                } catch(error) {
                    this.loadingGeneration = false;
                }
            }

            const title = this.$t('CONTROL.NOTIFIERS.GENERATE_DOCUMENTS_WARN');
            const text = this.$t('CONTROL.NOTIFIERS.GENERATE_DOCUMENTS_WARN_TEXT');
            this.$alert({
                type: 'warning',
                title,
                text,
                isTextHtml: true,
                confirmCallback,
            });

        }, 
        async checkDocumentStatus(id, isPreview) {
            const polling = isPreview ? this.previewPolling : this.polling;
            const status = isPreview ? await this.doCheckPreviewStatus(id) : await this.doCheckGenerationStatus(id);

            try {
                if (status !== 'initiated') {
                    this.documentStatus = status;
                    this.updateLoading(isPreview, false);
                    
                    clearInterval(polling);
                }

                if (status === 'completed') {
                    await this.handleDocumentCompleted();
                }

                if (status === 'error') {
                    await this.handleDocumentError();
                }
            } catch (error) {
                this.confirmationStatus = 'error';
                this.updateLoading(isPreview, false);
                clearInterval(polling);
                await this.handleDocumentError();
            }
        },
        async handleDocumentCompleted() {
            // refresh record
            await this.doFindRecord(this.id);

            const title = this.$t('CONTROL.NOTIFIERS.GENERATION_COMPLETED');
            const text = this.$t('CONTROL.NOTIFIERS.GENERATION_COMPLETED_TEXT');
            this.$alert({
                type: 'success',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        },
        async handleDocumentError() {
            // refresh record
            await this.doFindRecord(this.id);

            const title = this.$t('CONTROL.NOTIFIERS.GENERATION_ERROR');
            const text = this.$t('CONTROL.NOTIFIERS.GENERATION_ERROR_TEXT');
            this.$alert({
                type: 'error',
                title,
                text: text,
                isTextHtml: true,
                showConfirmButton: false,
            });
        },
        clearPollingScraps() {
            if (this.polling) {
                clearInterval(this.polling);
            }

            if (this.previewPolling) {
                clearInterval(this.previewPolling);
            }
        },
        async closeControl() {
            const confirmCallback = async () => {
                await this.doCloseControl(this.id);
    
                const title = this.$t('CONTROL.NOTIFIERS.CLOSE_CONTROL_SUCCESS');
                const text = this.$t('CONTROL.NOTIFIERS.CLOSE_CONTROL_SUCCESS_TEXT');
                this.$alert({
                    type: 'success',
                    title,
                    text: text,
                    isTextHtml: true,
                    showConfirmButton: false,
                    callback: () => {
                        this.$router.push(this.backLink);
                    },
                });
            }

            const title = this.$t('CONTROL.NOTIFIERS.CLOSE_CONTROL_WARN');
            const text = this.$t('CONTROL.NOTIFIERS.CLOSE_CONTROL_WARN_TEXT');
            this.$alert({
                type: 'warning',
                title,
                text,
                isTextHtml: true,
                confirmCallback,
            });
        },
        async previewDocument() {
            const confirmCallback = async () => { 
                try {
                    this.loadingPreview = true;
                    const response = await this.doPreviewDocument(this.id);
                    switch(response) {
                        case 'error':
                            this.previewStatus = response;
                            this.loadingPreview = false;
                            break;
                        case 'initiated':
                            this.previewPolling = setInterval(() => {
                                this.checkDocumentStatus(this.id, true);
                            }, 1000);
                            break;
                        }
                    } catch(error) {
                        this.loadingPreview = false;
                    }
            }

            const title = this.$t('CONTROL.NOTIFIERS.PREVIEW_DOCUMENTS_WARN');
            const text = this.$t('CONTROL.NOTIFIERS.PREVIEW_DOCUMENTS_WARN_TEXT');
            this.$alert({
                type: 'warning',
                title,
                text,
                isTextHtml: true,
                confirmCallback,
            });
        },
        updateLoading(isPreview, loading) {
            if (isPreview) {
                this.loadingPreview = loading;
            } else {
                this.loadingGeneration = loading;
            }
        }
    },
    async created() {
         if (this.id && !this.record) {
            await this.doFindRecord(this.id);
        }

        if (this.id) {
            this.disableTab = this.disableTab.map(() => false);
        }

    },
    beforeDestroy() {
        this.initDefaultState();
    },
    destroy() {
        if (this.polling){
            clearInterval(this.polling);
        }
    }
}
</script>
