<template>
    <div>
        <st-button
            v-if="canUpload"
            variant="primary"
            :callback="addDocument"
            class="ml-6"
        >
            <i class="fa fa-plus"></i>
            {{ $t('CONTROL.BUTTON.ADD_DOCUMENT') }}
        </st-button>

        <control-documents-upload-modal
            ref="documents-upload-modal"
            :controlId="controlId"
        >
        </control-documents-upload-modal>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { ControlPermissions } from "@/modules/control/control-permissions";

    export default {
        name: 'ControlDocumentsListToolbar',
        components: {
            ControlDocumentsUploadModal: () => import("./ControlDocumentsUploadModal.vue"),
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                record: 'control/form/record'
            }),
            controlId() {
                return this.record.id;
            },
            canUpload() {
                return (this.hasPermissionToReadStaff && !['closed'].includes(this.record?.status));
            },
            hasPermissionToReadStaff() {
                return new ControlPermissions(this.currentUser).read;
            },
        },
        methods: {
            ...mapActions({
                doFind: "control/form/find"
            }),
            addDocument() {
                this.$refs['documents-upload-modal'].show();
            }
        },
    };
</script>