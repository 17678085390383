<template>
    <div>
        <Loader v-if="isLoading" />

        <div class="d-flex justify-content-end">
            <st-button
                variant="light"
                class="mt-6 st-btn-text-primary"
                :callback="collectData"
                v-if="showSaveBtn"
            >
                <i class="fas fa-save"></i>{{$t('CONTROL.BUTTON.SAVE')}}
            </st-button>
        </div>

        <control-type-form
            ref="controlTypeForm"
            @change="doSelectControlType"
            :viewMode="viewMode"
        />

        <st-section :header="$t('CONTROL.CURRENT_LOCATION')" class="mb-10">
            <template #body>
                <location-map
                    :viewMode="viewMode"
                    :address="staffLocation.address"
                    :location="staffLocation.location"
                    :canEdit="canEdit"
                    @selectLocation="selectLocation"
                    containerId="largeMap"
                />
            </template>
        </st-section>

        <generate-form
            v-if="formSchema"
            :data="formSchema"
            :value="formData"
            ref="generateForm"
            @modelUpdated="updateFormData"
            :viewMode="viewMode"
            :fileToken="fileToken"
        ></generate-form>

        <div class="d-flex justify-content-end">
            <st-button
                variant="light"
                class="mt-6 st-btn-text-primary"
                :callback="collectData"
                v-if="showSaveBtn"
            >
                <i class="fas fa-save"></i>{{$t('CONTROL.BUTTON.SAVE')}}
            </st-button>
        </div>
    </div>
</template>

<script>
import GenerateForm from "@/modules/forms/components/generate-form/GenerateForm.vue";
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import ControlTypeForm from "@/modules/control/components/ControlTypeForm";
import Message from "@/shared/message/message";
import /* webpackChunkName: "locationmap" */ LocationMap from "@/modules/complain/components/LocationMap.vue";

export default {
    name: 'ControlDataCollection',
    components: {
        GenerateForm,
        ControlTypeForm,
        LocationMap
    },
    data: () => ({
        formSchema: null,
        formData: {},
        staffLocation: {
            address: '',
            location: {}
        },
    }),
    computed: {
        ...mapGetters({
            uploadAccessToken: "control/form/uploadAccessToken",
            currentUser: "auth/currentUser",
            record: "control/form/record",
            accountDetails: "auth/accountDetails",
            loading: "shared/loading",
            selectedComplains: 'control/form/currentComplains',
            fileToken: "control/form/fileToken",
        }),
        isLoading() {
            return this.loading['control/create'] || this.loading['control/update'] || this.loading['control/upload']
        },
        showSaveBtn() {
            return (this.record?.status === 'registered') || !this.record;
        },
        showAtlas() {
            return this.record?.form?.realty?.length;
        },
        realtyList() {
            if (!this.formData?.realty?.length) return [];
            return this.formData.realty.filter((el) => el?.coordinates?.length);
        },
        viewMode() {
            return this.record && !this.record.can_edit_control;
        },
        canEdit() {
            return this.record?.status === 'registered';
        }
    },
    methods: {
        ...mapActions({
            doFindForm: "control/form/findForm",
            doCreate: "control/form/create",
            doFindControlType: "control/form/findControlType",
            doUpload: "control/form/upload",
            doUpdate: "control/form/update",
            initDefaultState: 'control/form/initFormState',
            doFindRecord: 'control/form/find',
        }),
        updateFormData(data, key) {
            Vue.set(this.formData, key, data);
        },
        async collectData() {
            try {
                const controlTypeForm = this.$refs.controlTypeForm;
                const validateControlTypeForm = await controlTypeForm.fv.validate();
                if (validateControlTypeForm === "Valid") {
                    const generatedFormData = await this.$refs.generateForm.getData();

                    if (generatedFormData) {
                        generatedFormData.control_type_id = controlTypeForm.model.control_type_id;
                        generatedFormData.control_type_name = controlTypeForm.fields.control_type_id.options.find(
                                (el) => el.value === controlTypeForm.model.control_type_id).text;

                        generatedFormData.related_complains = this.selectedComplains || [];

                        if (Object.keys(this.staffLocation).length) {
                            generatedFormData.staff_location = this.staffLocation;
                        }

                        let control = null;
                        if (!this.record) {
                            control = await this.doCreate(generatedFormData);
                        } else {
                            control = await this.doUpdate({
                                id: this.record.id,
                                values: {
                                    form: generatedFormData.form,
                                    staff_location: this.staffLocation,
                                    related_complains: generatedFormData.related_complains,
                                }
                            });
                        }

                        if (control) {
                            const { uploadedFiles, uploadedIdentificationFiles } = await this.handleUploadFiles(generatedFormData.form);
                            if (uploadedFiles.length || uploadedIdentificationFiles.length) {
                                await this.doUpdate({
                                    id: control.id,
                                    values: {
                                        form: {
                                            ...control.form,
                                            ...uploadedFiles
                                        },
                                        identification_files: uploadedIdentificationFiles,
                                    },
                                });
                            }

                            if (!this.$route.params.id) {
                                this.$emit('nextStep', {redirect: true, id: control.id});
                            } else {
                                await this.clearData(this.record);
                            }
                        }
                    }
                }
            } catch (error) {
                Message.error('CONTROL.NOTIFIERS.DEFAULT_ERROR_MESSAGE');
            }
        },
        async doSelectControlType({ control_type_id }) {
            if (control_type_id) {
                const controlType = await this.doFindControlType(control_type_id);
                if (controlType?.form_id) {
                    this.doFindForm(controlType.form_id).then((form) => {
                        this.formSchema = form.schema;
                    });
                }
            }
        },
        getFileData(data) {
            const fileItems = {};
            let existentFiles = {};
            Object.keys(data).forEach((key) => {
                const formItem = data[key];
                let areNewFiles = false;

                if (Array.isArray(formItem) && formItem.length) {
                    const formDataObj = new FormData();
                    const fileList = []
                    for (let i = 0; i < formItem.length; i++) {
                        const file = formItem[i];
                        if (file instanceof File) {
                            formDataObj.append("files", file);
                            areNewFiles = true;
                        } else {
                            if (file?.file_id) {
                                fileList.push(file);
                            }
                        }
                    }

                    if (areNewFiles) {
                        fileItems[key] = formDataObj;
                    }

                    if (fileList.length) {
                        existentFiles[key] = fileList;
                    }

                    delete data[key];
                }
            });

            return { fileItems, existentFiles };
        },
        async handleUploadFiles(data) {
            try {
                let uploadedIdentificationFiles = [];
                let uploadedFiles = [];

                const { fileItems, existentFiles } = this.getFileData(data);

                if (fileItems && this.uploadAccessToken) {
                    this.isUploadingPending = true;
                    for (let i = 0; i < Object.keys(fileItems).length; i++) {
                        const inputFileName = Object.keys(fileItems)[i];
                        const files = fileItems[inputFileName];
                        const currentUploadedFiles = await this.doUpload({files, uploadAccessToken: this.uploadAccessToken});

                        uploadedFiles[inputFileName] = currentUploadedFiles.map(
                            (el) => ({
                                file_id: el.id,
                                filename: el.initialFilename,
                                created_date: el.created_date,
                                file_type: "input",
                                created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                            })
                        );

                        if (existentFiles[inputFileName]) {
                            uploadedFiles[inputFileName] = [...uploadedFiles[inputFileName], ...existentFiles[inputFileName]];
                        }

                        uploadedIdentificationFiles = [...uploadedIdentificationFiles, ...uploadedFiles[inputFileName]];
                    }

                    this.isUploadingPending = false;
                }

                if (Object.keys(existentFiles).length && !Object.keys(fileItems)?.length) {
                    for (let i = 0; i < Object.keys(existentFiles).length; i++) {
                        const inputFileName = Object.keys(existentFiles)[i];
                        uploadedIdentificationFiles = [...uploadedIdentificationFiles, ...existentFiles[inputFileName]];
                    }
                }

                return {
                    uploadedIdentificationFiles,
                    uploadedFiles
                };

            } catch (error) {
                this.isUploadingPending = false;
            }
        },
        async clearData(data) {
            await this.$refs.generateForm.clearGenerateFormData(data);
        },
        generatedMapLoaded() {
            this.$emit("map-mounted");
        },
        selectLocation(data) {
            const location = {
                latitude: data.location.latitude,
                longitude: data.location.longitude
            }

            this.staffLocation = {
                address: data.address,
                locality_name: data.attributes.City,
                location
            }
        }
    },
    async created() {
        if (this.$route.params.id && !this.record) {
            await this.doFindRecord(this.$route.params.id);
        }

        if (this.record?.id) {
            this.formData = this.record.form;
            this.formData.control_type_id = this.record.control_type_id;

            if (this.record?.staff_location) {
                this.staffLocation = this.record?.staff_location;
            }
        }
    }
}
</script>
