<template lang="">
    <div :id="containerId"></div>
</template>

<script>
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Search from "@arcgis/core/widgets/Search";
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import * as locator from "@arcgis/core/rest/locator";
import esriConfig from "@arcgis/core/config.js";
import esriConfigJson from "@/config/esri.config.json";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as projection from "@arcgis/core/geometry/projection";
import Point from "@arcgis/core/geometry/Point";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";


let GIS = {};
const serviceUrl = "http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer";

const marker = {
  type: "simple-marker",
  style: "circle",
  color: "#1855C5",
  size: "16px",
  outline: {
    color: "#75A0F0",
    width: 3,
  },
};

const popupTemplate = {
  title: "{Address}",
  content: "{Description}",
};

export default {
  name: "LocationMap",
  props: {
    viewMode: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: () => {},
    },
    address: {
      type: String,
      default: "",
    },
    locations: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    containerId: {
      type: String,
      default: 'largeMap'
    }
  },
  watch: {
    locations(newVal) {
      this.showAddresses(newVal);
    }
  },
  data () {
    return {
      locationsList: this.locations
    }
  },
  methods: {
    setSelectedAddress(data) {
      this.$emit("selectLocation", data);
    },
    getLocationToAddress(evt) {
      const params = {
        location: evt.mapPoint,
      };
      locator.locationToAddress(serviceUrl, params).then(
        (response) => {
          // Show the address found
          const address = response.address;
          let locationWithStereo70 = {
            latitude: response.location.latitude,
            longitude: response.location.longitude,
            latitude_stereo70: null,
            longitude_stereo70: null,
          };
          projection
            .load()
            .then(() => {
                const gpsPoint = new Point({
                    x: locationWithStereo70.longitude,
                    y: locationWithStereo70.latitude,
                });

                let outSpatialReferenceS70 = new SpatialReference({
                    wkid: 31700, //stereo70
                    // wkid: 102100, //Sphere_Sinusoidal projection
                });

                const s70Point = projection.project(
                    gpsPoint,
                    outSpatialReferenceS70
                )

                locationWithStereo70.latitude_stereo70 = s70Point.y;
                locationWithStereo70.longitude_stereo70 = s70Point.x;

                this.setSelectedAddress(
                    {
                        address: response.address,
                        attributes: response.attributes,
                        location: locationWithStereo70,
                    }
                );
            });

            this.showAddress(address, response.location);
        },
        (err) => {
          // Show no address found
          this.showAddress(this.$t("ERRORS.ATLAS_NO_SEARCH_RESULT"), evt.mapPoint);
        }
      );
    },
    showAddresses(locations) {
        GIS.graphicsLayer.removeAll();
        locations.forEach((location) => {
            const address = location.address;
            const coordonates = location.location;
            const multipleLocations = true;
            this.showAddress(address, coordonates, multipleLocations);
        })
    },
    showAddress(address, pt, multipleLocations = false) {
      if(!multipleLocations) {
          GIS.graphicsLayer.removeAll();
      }
        // Create a point
        const point = {
          type: "point",
          longitude: pt.longitude,
          latitude: pt.latitude,
        };
        const popupDescription = `${pt.longitude}, ${pt.latitude}`;

        const attributes = {
          Address: this.address,
          Description: popupDescription,
        };

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: marker,
          attributes,
          popupTemplate,
        });

        GIS.graphicsLayer.add(pointGraphic);

        if (this.viewMode && !multipleLocations) {
          GIS.view.center = [pt.longitude, pt.latitude];
          GIS.view.zoom = 17;
        }

        const title = address != '' ? address : this.$t('GENERAL.MISSING_ADDRESS');
        if (!multipleLocations) {
            GIS.view.popup.open({
                title,
                content: popupDescription,
                location: pt,
            });
        }
    },
  },
  mounted() {
    esriConfig.apiKey = esriConfigJson.apiKey;
    const map = new Map({
      basemap: "topo-vector",
    });

    const view = new MapView({
      container: this.containerId,
      map: map,
      center: [23.5889, 46.7698],
      zoom: 11,
    });

    //basemap toggle
    const basemapToggle = new BasemapToggle({
      view: view,
      nextBasemap: "arcgis-imagery",
    });
    view.ui.add(basemapToggle, "bottom-right");

    const graphicsLayer = new GraphicsLayer();
    map.add(graphicsLayer);

    GIS.map = map;
    GIS.view = view;
    GIS.graphicsLayer = graphicsLayer;

    if (!this.viewMode) {
      // Add Search widget
      const search = new Search({
        view: view,
      });
      view.ui.add(search, "top-right");

      view.on("click", this.getLocationToAddress);
    }

    if (this.canEdit || this.viewMode) {
      if (this.location) {
            this.showAddress(this.address, this.location);
        }
        if (this.locations.length > 0) {
            this.showAddresses(this.locations) ;
        }
    }
  },
};
</script>
<style>
/* esri styles */
@import url("https://js.arcgis.com/4.26/esri/themes/light/main.css");

#largeMap {
  height: 600px;
}

#smallMap {
  height: 200px;
}
</style>
