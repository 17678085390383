import * as yup from 'yup';
import moment from 'moment';
import GenericField from '@/shared/fields/generic-field';
import { validators } from '@/shared/form/validators';

export default class DateTimeField extends GenericField {
    constructor(name, label, { required = false } = {}, filterParams = {}) {
        super(name, label, filterParams);

        this.required = required;
        this.type = 'daterange';
    }

    forPresenter(value) {
        return value ? moment(value).format('DD-MM-YYYY HH:mm') : null;
    }

    forFilterCast() {
        return yup
            .mixed()
            .nullable(true)
            .label(this.label)
            .transform((value) =>
                value ? moment(value).format('DD-MM-YYYY HH:mm') : null,
            );
    }

    forFormRules() {
        let output = {};

        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }

        return output;
    }

    forFilterRules() {
        return undefined;
    }

    forFormInitialValue(value) {
        return value;
    }

    forFormCast() {
        let yupChain = yup
            .mixed()
            .nullable(true)
            .label(this.label);

        return yupChain;
    }
}
