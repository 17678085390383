import moment from "moment";

const FILTERS = {
    search: (key, value) => ({ [key]: { value, operator: 'includes' } }),
    select: (key, value) => ({ [key]: { value, operator: 'eq' } }),
    autocomplete: (key, value) => ({ [key]: { value, operator: 'eq' } }),
    contains: (key, value) => ({ [key]: { value, operator: 'includes' } }),
    daterange: (key, value) => {

        const start = value.start && moment(value.start);
        const end = value.end && moment(value.end).add(1, 'day');

        return {
            [key]: {
                value: {
                    start,
                    end,
                },
                operator: 'daterange'
            }
        }
    }
};

const calcFiltersParams = (filters) => {
    const queries = filters
        .filter(filter => {
            if (filter.type === 'daterange') {
                return filter.value.start || filter.value.end;
            }
            return filter.value;
        })
        .map(filter => {
            if (filter.searchByText && filter.type ==='contains') {
                const filterValue = filter.options.find((filterItem) => filterItem.value === filter.value)?.text;
                return FILTERS[filter.type](filter.key, filterValue)
            }

            return FILTERS[filter.type](filter.key, filter.value)
        })

    if (queries?.length) {
        const params = Object.assign({}, ...queries);
    
        const filterFn = (item) => {
            for (const key in params) {
                const currentValue = item[key];

                if (currentValue === undefined) {
                    return false;
                }
    
                const { operator, value } = params[key];
                if (operator === 'eq' && currentValue !== value) {
                    return false;
                }

                if (operator === 'includes' && !currentValue.includes(value)) {
                    return false;
                }

                if (operator === 'daterange') {
                    const { start, end } = value;

                    const currentValueMoment = moment(currentValue);

                    if (start && end) {
                        return currentValueMoment.isSameOrAfter(start)
                            && currentValueMoment.isBefore(end);
                    }
                    if (start) {
                        return currentValueMoment.isSameOrAfter(start);
                    }
                    if (end) {
                        return currentValueMoment.isBefore(end);
                    }
                }
            }
            return true;
        }
        return { filterFn };
    }

    return {};

}

const calcInMemoryFilterFnParams = (params) => {
    const { filters } = params;
    const filtersParams = calcFiltersParams(filters);

    return {
        ...filtersParams,
    };
}

export default calcInMemoryFilterFnParams;
